<template>
    <article class="nosotros">
        <div class="inte">
            <img src="https://noviasdiez.com/tem/edi.png" alt="Edinson Acevedo" title="Edinson Acevedo">
            <p>
                <span><b>Scrum Master</b></span>
                Edinson Acevedo
            </p>
        </div>
        <div class="inte">
            <img src="https://noviasdiez.com/tem/juan.png" alt="Juan Giraldo" title="Juan Giraldo">
            <p>
                <span><b><b>Frontend</b></b></span>
                Juan Giraldo
            </p>
        </div>
        <div class="inte">
            <img src="https://noviasdiez.com/tem/jesus.png" alt="Jesús Bastidas" title="Jesús Bastidas">
            <p>
                <span><b>Tech Advisor</b></span>
                Jesús Bastidas
            </p>
        </div>
        <div class="inte">
            <img src="https://noviasdiez.com/tem/ronal.png" alt="Ronald Hernández" title="Ronald Hernández">
            <p>
                <span><b>SQL</b></span>
                Ronald Hernández
            </p>
        </div>
        <h2>
            "Innovation distinguishes between a leader and a follower."<br><span>Steve Jobs</span>
        </h2>
    </article>
</template>